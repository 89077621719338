<section class="breadcrumb-main pb-20 pt-14" style="background-image: url(assets/images/bg/bg1.jpg);">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="breadcrumb-outer">
        <div class="container">
            <div class="breadcrumb-content text-center">
                <h1 class="mb-3">About Us</h1>
                <nav aria-label="breadcrumb" class="d-block">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">About Us</li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="dot-overlay"></div>
</section>

<section class="about-us pt-6"
    style="background-image:url(images/background_pattern.png); background-position:bottom right;">
    <div class="container">
        <div class="about-image-box">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-lg-6 ps-4">
                    <div class="about-content text-center text-lg-start">
                        <h4 class="theme d-inline-block mb-0">Get To Know Us</h4>
                        <h2 class="border-b mb-2 pb-1">Explore All Tour of the world with us.</h2>
                        <p class="border-b mb-2 pb-2">
                            {{settings.siteName}} is based in Chennai and our service details are provided as below. we
                            provide return trip also along with one way if needed. We are the leading outdoor taxi
                            service
                            in Chennai by providing best service at the lowest fare.For any
                            queries, you may contact our customer service
                            <a href="tel:{{settings.phoneNo}}"></a>.
                        </p>
                        <div class="about-listing">
                            <ul class="d-flex justify-content-between">
                                <li><i class="icon-location-pin theme"></i> Quality Drivers</li>
                                <li><i class="icon-briefcase theme"></i> Friendly Price</li>
                                <li><i class="icon-folder theme"></i> Reliable Tour Package</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mb-4 pe-4">
                    <div class="about-image" style="animation:none; background:transparent;">
                        <img src="images/travel.png" alt="">
                    </div>
                </div>
                <div class="col-lg-12">

                    <div class="counter-main w-75 float-start z-index3 position-relative">
                        <div class="counter p-4 pb-0 box-shadow bg-white rounded mt-minus">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                                    <div class="counter-item border-end pe-4">
                                        <div class="counter-content">
                                            <h2 class="value mb-0 theme">20</h2>
                                            <span class="m-0">Years Experiences</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                                    <div class="counter-item border-end pe-4">
                                        <div class="counter-content">
                                            <h2 class="value mb-0 theme">530</h2>
                                            <span class="m-0">Tour Packages</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                                    <div class="counter-item border-end pe-4">
                                        <div class="counter-content">
                                            <h2 class="value mb-0 theme">850</h2>
                                            <span class="m-0">Happy Customers</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                                    <div class="counter-item">
                                        <div class="counter-content">
                                            <h2 class="value mb-0 theme">320</h2>
                                            <span class="m-0">Award Winning</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>