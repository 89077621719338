<section class="breadcrumb-main pb-20 pt-14" style="background-image: url(assets/images/bg/bg1.jpg);">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="breadcrumb-outer">
        <div class="container">
            <div class="breadcrumb-content text-center">
                <h1 class="mb-3">Outstation Taxi Routes</h1>
                <nav aria-label="breadcrumb" class="d-block">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Outstation Taxi</li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="dot-overlay"></div>
</section>


<div class="container">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let city of places">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    One Way Outstation Taxi in {{city}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let subCity of places">
                <h6 class="city" *ngIf="city !== subCity">
                    <a class="text-black-600 caption font-weight-semibold" (click)="selectedCity(city, subCity )"
                        routerLink="/city-detail/{{city | lowercase}}-to-{{subCity | lowercase}}-outstation-taxi">
                        {{city}} to {{subCity}} One Way Cabs</a>
                </h6>
            </ng-container>

        </mat-expansion-panel>
    </mat-accordion>
</div>