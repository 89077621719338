<div class="eael-tabs-nav">
    <ul class="eael-tab-top-icon">

        <li (click)="tripTypeSelect('One Way')" id="flight" [ngClass]="tripType === 'One Way' ? 'active': 'inactive'">
            <mat-icon>local_taxi</mat-icon>
            <span class="eael-tab-title title-after-icon">One Way</span>
        </li>

        <li (click)="tripTypeSelect('Round Trip')" id="hotels"
            [ngClass]="tripType === 'Round Trip' ? 'active': 'inactive'">
            <mat-icon>360</mat-icon>
            <span class="eael-tab-title title-after-icon">Round Trip</span>
        </li>

          <li (click)="tripTypeSelect('Rental')" id="train" [ngClass]="tripType === 'Rental' ? 'active': 'inactive'">
            <mat-icon>history</mat-icon>
            <span class="eael-tab-title title-after-icon">Rental</span>
        </li>

        <li (click)="tripTypeSelect('Airport')" id="train" [ngClass]="tripType === 'Airport' ? 'active': 'inactive'">
            <mat-icon>flight</mat-icon>
            <span class="eael-tab-title title-after-icon">Airport</span>
        </li>
    </ul>
</div>

<div id="booking">
    <ng-container *ngIf="tripType === 'One Way'">
        <app-oneway></app-oneway>
    </ng-container>
    <ng-container *ngIf="tripType === 'Round Trip'">
        <app-round></app-round>
    </ng-container>
    <ng-container *ngIf="tripType === 'Rental'">
        <app-rental></app-rental>
    </ng-container>
    <ng-container *ngIf="tripType === 'Airport'">
       <app-airport></app-airport>
    </ng-container>
</div>