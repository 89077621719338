import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CanonicalService } from 'src/app/canonical.service';
import { POPULAR_CITIES } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css']
})
export class CityListComponent implements OnInit {
  settings = SITE_SETTINGS;
  cityName;
  places = POPULAR_CITIES;
  place;
  title;

  constructor(private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta, @Inject(DOCUMENT) private document: Document ) { }

  ngOnInit(): void {
  

    this.cityName = this.activeRoute.snapshot.paramMap.get('cityName').split('-')[0];
    this.place = this.places.filter(place => place.cityName.toLowerCase() === this.cityName.toLowerCase())[0];


    this.title = `${this.cityName} Drop Taxi Services | One Way Drop Taxi Service ${this.cityName} | Outstation Cabs`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Book your drop taxi from ${this.cityName}. Book your outstation cabs at reasonable rates. Save 40% on one way taxi booking ${this.cityName} compared to other drop taxi service providers.` },
    );

    this.metaTagService.updateTag(
      { name: 'keywords', content: `${this.cityName} drop taxi, one way drop taxi ${this.cityName}, one way taxi ${this.cityName}, one way cabs ${this.cityName}, outstation cabs ${this.cityName}` }
    );


    this.metaTagService.updateTag({ property: 'og:title', content: this.title });
    this.metaTagService.updateTag({ property: 'og:description', content: `Book your drop taxi from ${this.cityName}. Book your outstation cabs at reasonable rates. Save 40% on one way taxi booking ${this.cityName} compared to other drop taxi service providers.` })
    this.metaTagService.updateTag({ property: 'og:keywords', content: `${this.cityName} drop taxi, one way drop taxi ${this.cityName}, one way taxi ${this.cityName}, one way cabs ${this.cityName}, outstation cabs ${this.cityName}`  })

    this.metaTagService.updateTag({ name: 'twitter:title', content: this.title });
    this.metaTagService.updateTag({ name: 'twitter:description', content: `Book your drop taxi from ${this.cityName}. Book your outstation cabs at reasonable rates. Save 40% on one way taxi booking ${this.cityName} compared to other drop taxi service providers.` })

  }

  destroyLinkForCanonicalURL() {
    const els = this.document.querySelectorAll('link[rel=\'canonical\']');
    console.log(els);
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
    this.canonicalService.setCanonicalURL();
  }
}

