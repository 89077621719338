<div class="container">
    <div class="row align-items-center vh-100">
        <div class="col-md-10 col-lg-7 m-auto py-4 py-md-5">
            <div class="text-center">
                <h1 class="mb-4">Success!</h1>
                <p class="text-black-600">Thanks for Choosting {{settings.siteName}}, reservation details have been sent to your email ID and phone.</p>
                <div class="py-4 py-lg-5">
                    <img src="assets/travel-banner.svg" alt="404">
                </div>
                <p><a routerLink="/" class="btn btn-lg btn-warning">Back Home</a></p>
            </div>
        </div>
    </div>
</div>