<div class="container">
    <section class="muse-section py-4 py-md-5">
        <div class="row align-items-center">
            <div class="col-md-12 mb-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <h4 class="mb-1 h1" style="font-size: 18px;">
                    <img (click)="back()" style="
              width: 30px;
              margin-right: 15px;
          " src="assets/left-arrow.png" alt="" srcset=""> Enter contact Details</h4>
                <!-- <p class="big lh-lg">Get in touch and a team of highly trained ninjas will get back to you</p> -->
            </div>
            <div class="col-md-12">
                <div class="card border-gray-200 rounded-12 shadow-dark-80 p-4 p-md-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                    <form [formGroup]="contactFormGroup" (ngSubmit)="booknow()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Full Name</label>
                                    <input type="text" class="form-control" placeholder="Full name" formControlName="name" placeholder="Enter your name">
                                    <div *ngIf="submitted && contactValidation.name.errors" class="invalid-feedback" style="display: block;">
                                        <div *ngIf="contactValidation.name?.errors.required">Name is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Email</label>
                                    <input type="text" class="form-control" placeholder="muse@fabrx.co" formControlName="email">
                                    <div *ngIf="submitted && contactValidation.email.errors" class="invalid-feedback" style="display: block;">
                                        <div *ngIf="contactValidation.email?.errors.required">Email is required</div>
                                        <div *ngIf="contactValidation.email?.errors.pattern">Enter valid email address</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Mobile Number</label>
                                    <input type="number" class="form-control" placeholder="9841223344" formControlName="phone">
                                    <div *ngIf="submitted && contactValidation.phone.errors" class="invalid-feedback" style="display: block;">
                                        <div *ngIf="contactValidation.phone?.errors.required">Mobile number is required</div>
                                        <div *ngIf="contactValidation.phone?.errors.pattern">Enter valid mobile number</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Pickup Date</label>
                                    <input [min]="minDate" type="text" class="form-control" placeholder="Select Date" required="" formControlName="pickupDate" (focus)="picker.open()" [matDatepicker]="picker">
                                    <mat-datepicker #picker></mat-datepicker>
                                    <div *ngIf="submitted && contactValidation.pickupDate.errors" class="invalid-feedback" style="display: block;">
                                        <div *ngIf="contactValidation.pickupDate?.errors.required">Pickup date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Pickup Time</label>
                                    <input type="text" class="form-control" placeholder="Select Time" required="" formControlName="pickupTime" [ngxTimepicker]="time">
                                    <ngx-material-timepicker #time></ngx-material-timepicker>
                                    <div *ngIf="submitted && contactValidation.pickupTime.errors" class="invalid-feedback" style="display: block;">
                                        <div *ngIf="contactValidation.pickupTime?.errors.required">Pickup time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div *ngIf="rideDetails && rideDetails.tripType !== 'One Way'" class="mb-4 mb-md-5">
                                    <label class="form-label form-label-lg">Retun Date</label>
                                    <input [min]="minDate" type="text" class="form-control" placeholder="Select Date" required="" formControlName="returnDate" (focus)="picker1.open()" [matDatepicker]="picker1">
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                            </div>

                            <div class="col-md-12 text-center">
                                <button (click)="booknow()" type="button" class="btn btn-warning">Book Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

    <h5 class="mb-3 text-center">Booking Summary</h5>

    <div *ngIf="bookingDetails" class="bg-white rounded-12 shadow-dark-80 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div class="border-bottom border-gray-200 px-4 px-md-5 py-4 d-flex align-items-center">
            <h5 class="mb-0">Selected Car</h5>
            <div class=" ml-auto"><a class="btn btn-sm btn-warning text-nowrap text-uppercase">{{bookingDetails.carType}}</a></div>
        </div>
        <div class="px-4 px-md-5 py-4">
            <div class="d-flex align-items-center">
                <div class="pr-2">
                    <h6 class="font-weight-normal">Location</h6>
                    <small class="d-block text-gray-600 lh-lg">
                      Origin: <strong>{{bookingDetails.origin}}</strong> <br>
                      Destination: <strong>{{bookingDetails.origin}}</strong> <br>
                    </small>
                </div>
            </div>
        </div>
        <hr class="bg-gray-200 my-0">
        <div class="px-4 px-md-5 py-4">
            <div class="d-flex align-items-center">
                <div class="pr-2">
                    <h6 class="font-weight-normal">Fare Breakup</h6>
                    <small class="d-block text-gray-600">
                      Base Fare: <strong>₹{{bookingDetails.baseFare}} </strong> <br>
                      Driver Batta: <strong>₹{{bookingDetails.driverBatta}} </strong> <br>
                      Rare per km: <strong>₹{{bookingDetails.ratePerKM}} </strong> <br>

                    </small>
                </div>
                <div class="ml-auto">
                    <span class="h5">
                      {{bookingDetails.tripEstimation | currency: 'INR'}}
                    </span>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="bookingDetails" class="bg-white rounded-12 shadow-dark-80 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div class="border-bottom border-gray-200 px-4 px-md-5 py-4">
            <h5 class="mb-0">For customer information</h5>
        </div>
        <div class="px-4 px-md-5 py-4">
            <div class="d-md-flex align-items-center">
                <div>
                    <ul class="plan-features aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        <li>
                            <small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                    <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                    <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                    </svg>
                    </small>
                            <div class="feature-right">
                                <p class="lh-lg">Excludes toll costs, parking, permits and state tax</p>
                            </div>
                        </li>
                        <li>
                            <small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                    <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                    <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                    </svg>
                    </small>
                            <div class="feature-right">
                                <p class="lh-lg">
                                    ₹120/hr will be charged for additional hours
                                </p>
                            </div>
                        </li>
                        <li>
                            <small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                    <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                    <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                    </svg>
                    </small>
                            <div class="feature-right">
                                <p class="lh-lg">
                                    ₹{{bookingDetails.ratePerKM}}/km will be charged for extra km
                                </p>
                            </div>
                        </li>
                        <li>
                            <small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                    <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                    <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                    </svg>
                    </small>
                            <div class="feature-right">
                                <p class="lh-lg">
                                    Driver allowance per 24 hours - ₹{{bookingDetails.driverBatta}}
                                </p>
                            </div>
                        </li>
                        <li>
                            <small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                    <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                    <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                    </svg>
                    </small>
                            <div class="feature-right">
                                <p class="lh-lg">
                                    Night time allowance (11:00 PM - 06:00 AM) - ₹200/night
                                </p>
                            </div>
                        </li>
                        <li>
                            <small>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10">
                  <rect data-name="Icons/Tabler/Check background" width="10" height="10" fill="none"></rect>
                  <path d="M9.224.133a.455.455,0,0,1,.687.592L9.867.776,3.806,6.837a.455.455,0,0,1-.592.044l-.051-.044L.133,3.806a.455.455,0,0,1,.592-.687l.051.044L3.485,5.872Z" transform="translate(0 1.25)" fill="#3485FD"></path>
                  </svg>
                  </small>
                            <div class="feature-right">
                                <p class="lh-lg">
                                    Extra fare may apply if you don't end trip at {{bookingDetails.destinationCityName}}
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
            <hr class="bg-gray-200 my-4">
        </div>
    </div>


</div>