  export const CARS = [
    {
      carType: 'Micro',
      'oneway': 13,
      'roundtrip': 10,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 300,
      'roundTripDriverFee': 300,
      availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
      passengers: 4,
      image: '/assets/sedan.png',
      activeImage: '/assets/sedan_a.png',
      h50kmFare: 1200,
      h100kmFare: 2200,
      h150kmFare: 3200,
      airportCostPerKM: 35,
      rentalCostPerKm: 25
    },
    {
      carType: 'Sedan',
      'oneway': 14,
      'roundtrip': 11,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 300,
      'roundTripDriverFee': 300,
      availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
      passengers: 4,
      image: '/assets/etios.png',
      activeImage: '/assets/etios_a.png'
,
      h50kmFare: 1200,
      h100kmFare: 2200,
      h150kmFare: 3200,
      airportCostPerKM: 40 ,
      rentalCostPerKm: 30
    },
    {
      carType: 'Suv',
      'oneway': 18,
      'roundtrip': 14,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
      passengers: 6,
      image: '/assets/suv.png',
      activeImage: '/assets/suv_a.png'  ,
      h50kmFare: 1500,
      h100kmFare: 3000,
      h150kmFare: 4000,
      airportCostPerKM: 45,
      rentalCostPerKm: 35
    },
    {
      carType: 'Innova',
      'oneway': 19,
      'roundtrip': 15,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
      passengers: 6,
      image: '/assets/innova.png',
      activeImage: '/assets/innova_a.png'
  ,
      h50kmFare: 1700,
      h100kmFare: 3000,
      h150kmFare: 4500,
      airportCostPerKM: 50,
      rentalCostPerKm: 40
    },
    {
      carType: 'Crysta',
      'oneway': 23,
      'roundtrip': 16,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'Innova Crysta',
      passengers: 6,
      image: '/assets/crysta.png',
      activeImage: '/assets/crysta_a.png'
 ,
      h50kmFare: 1200,
      h60kmFare: 2000,
      h80kmFare: 3000,
      h120kmFare: 4000,
      airportCostPerKM: 55,
      rentalCostPerKm: 45
    }
  ];