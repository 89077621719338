<section class="breadcrumb-main pb-20 pt-14" style="background-image: url(assets/images/bg/bg1.jpg);">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="breadcrumb-outer">
        <div class="container">
            <div class="breadcrumb-content text-center">
                <h1 class="mb-3"> {{origin}} to {{destination}} Taxi</h1>
                <nav aria-label="breadcrumb" class="d-block">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{origin}} to {{destination}} Taxi</li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="dot-overlay"></div>
</section>


<div class="container">
    <section class="muse-section pb-4 pb-md-5">
        <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-8">
                <h1 class="mt-4 mb-4 " style="font-size: 18px;">
                    {{title}}
                </h1>
                <p class="big lh-lg text-black-600">
                    Book {{origin}} to {{destination}} Outstation Cabs at just 9₹ per km. Choose from a range of
                    {{origin}} To {{destination}} cabs - round trips, and one-way cabs
                </p>
            </div>
        </div>

        <hr class="bg-gray-300">

        <div class="row pt-4">
            <div class="col-md-12">
                <h4 class="mb-3">Book Outstation cab from {{origin}} to {{destination}} </h4>
            </div>
            <div class="hide-map">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                    <ng-container *ngIf="showDirection">
                        <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
                        </agm-direction>
                    </ng-container>
                </agm-map>
            </div>

            <ng-container *ngIf="showEstimation">
                <app-outstation></app-outstation>
            </ng-container>


        </div>

        <hr class="bg-gray-300">

        <div class="row pt-4">
            <div class="col-md-12">
                <h4 class="mb-3">{{origin}} to {{destination}} outstation cab fare </h4>
            </div>
            <div class="col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <p *ngIf="routeDetails" class="lh-lg mb-4">
                    The distance from {{origin}} to {{destination}} is about {{routeDetails.totalDistance}}km. It take
                    approximately {{routeDetails.totalDuration}} to complete the journey. SEDAN and SUV type of cabs
                    offered by {{settings.siteName}} are well maintained and
                    clean. Our courteous drivers will ensure that your entire journey is comfortable and memorable.
                    {{settings.siteName}} offered world class cab service at reasonable price for all it routes
                    including {{origin}} to {{destination}}. Above
                    is a breakup of the fare charge for you outstation trip from {{origin}} to {{destination}}.
                </p>
            </div>
        </div>



        <hr class="bg-gray-300">

        <div class="row pt-4">
            <div class="col-md-12">
                <h4 class="mb-3">Outstation cabs from {{origin}} to {{destination}} </h4>
            </div>
            <div class="col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <p class="lh-lg mb-4">
                    Make the most outstanding use of the services offered by {{settings.siteName}} in the most excellent
                    prices & go through the comforting and trouble-free journey by hiring {{origin}} to {{destination}}
                    taxi at one way fare. With in excess of a decade of
                    ability in car rental providers, {{settings.siteName}} is the best taxi service provider in the
                    business, if you’re preparation to employ a cab.
                </p>
            </div>
        </div>




    </section>

    <section *ngIf="routeDetails" class="muse-section pb-0 pb-sm-5 aos-init aos-animate" data-aos="fade-up"
        data-aos-delay="100">
        <h2 class="border-bottom border-gray-200 pb-4 mb-4">Frequently asked questions</h2>
        <div class="row pt-2">
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-2 mb-md-3">What is the distance from {{origin}} to {{destination}} by car?</h5>
                <p class="lh-lg">
                    The distance from {{origin}} to {{destination}} by car is about {{routeDetails.totalDistance}}km.
                    More information about our trariff just click <a routerLink="/tariff">here</a>
                </p>
            </div>
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-3">What type of vehicle’s are avilable ? </h5>
                <p class="mb-3 lh-lg">
                    We provide vatiry of cars from Sedan to Suv. <br><br> SEDAN :

                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1"> Swift Dzire</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Toyota Etios</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Hyundai Xcent</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Tata Zest</span> <br> <br> SUV :
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Xylo</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Innova</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Tavera</span>
                </p>
            </div>
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-2 mb-md-3">Is your service avilable all over Tamilnadu ?</h5>
                <p class="lh-lg">Yes, our service in all over Tamil Nadu.
                </p>
            </div>
        </div>
    </section>


</div>