import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';
@Component({
  selector: 'app-airport',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.css']
})
export class AirportComponent implements OnInit {



  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChildren('formRow') rows: any;

  sentOtpCode;
  rideDetails;
  settings = SITE_SETTINGS;
  showOtpError;
  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4'];
  enteredOtpCode;
  successfullySentOtpMsg = false;
  invalideOtp = false;
  display: any;
  showTimer = true;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  cars = [];
  selectedCar;
  origin;
  destination;
  requestRide;
  totalDistance;
  totalDuration;
  pickupPlace;
  dropPlace;
  journeyType = ['One Way', 'Round Trip'];
  selectedTripType = 'One Way';
  driverBata;
  baseFare;
  estimation;
  parseFloat: any;
  whatsupText;
  isBrowser: boolean;
  selectedDate;
  estimationDistance;
  bookingDetails;
  editable: boolean = true;
  minDate = new Date();
  submitted = false;
  tomorrow = new Date();
  originCityName;
  destinationCityName;
  routeDetails;
  selectedCarName;
  show = false;
  days;
  showOtpScreen;
  airportTripType = [
    { value: 'Cabs From the Airport', viewValue: 'Cabs From the Airport' },
    { value: 'Cabs to the Airport', viewValue: 'Cabs to the Airport' },

  ]

  rentalCity = [
    { value: 'Chennai', viewValue: 'Chennai' },
    { value: 'Coimbatore', viewValue: 'Coimbatore' },
    { value: 'Trichy', viewValue: 'Trichy' },
    { value: 'Tuticorin', viewValue: 'Tuticorin' },
    { value: 'Salem', viewValue: 'Salem' },
    { value: 'Pondicherry', viewValue: 'Pondicherry' },
    { value: 'Donakonda', viewValue: 'Donakonda' },
    { value: 'Nadirgul', viewValue: 'Nadirgul' },
    { value: 'Tirupati', viewValue: 'Tirupati' },
    { value: 'Vishakhapatnam', viewValue: 'Vishakhapatnam' },
    { value: 'Warangal', viewValue: 'Warangal' },
    { value: 'Bengaluru', viewValue: 'Bengaluru' },
    { value: 'Mangalore	', viewValue: 'Mangalore' },
    { value: 'Vijayapura', viewValue: 'Vijayapura	' },
    { value: 'Kochi	', viewValue: 'Kochi' },
  ]

  constructor(private _formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private taxiService: TaxiService,
    private http: HttpClient,
    private location: LocationStrategy,
    private dateAdapter: DateAdapter<Date>

  ) {
    this.dateAdapter.setLocale('en-GB');

    this.timer(1);
  }

  ngOnInit() {
    // if (!firebase.apps.length) {
    //   firebase.initializeApp(environment.firebaseConfig);
    // }

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
      }
    })


    this.tomorrow.setDate(new Date().getDate() + 1);


    this.firstFormGroup = this._formBuilder.group({
      tripType: ['Airport', Validators.required],
      name: ['', Validators.required],
      origin: ['Bengaluru', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      airportTripType: ['Cabs From the Airport', Validators.required],
      email: ['']
    });

    this.firstFormGroup.get('pickupTime').setValue(moment(Date.now()).format('h:mm a'));
    this.form = this.toFormGroup(this.formInput);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getEstimation();
    }, 1000);
  }

  get contactValidation() { return this.firstFormGroup.controls; }


  checkDate(start, end) {

    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
  }

  selectedCarType(ev) {
    this.selectedCar = ev;
    this.selectedCarName = ev.carType
  }



  requestAride() {

    this.submitted = true;

    if (this.firstFormGroup.invalid) {
      return;
    }

    if (!this.selectedCar) {
      alert('Select preffered vechicle type');
      return
    }

    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails,
      ...this.selectedCar
    }

    if (this.settings.OTP) {
      this.showOtpScreen = true;
      this.sendOtp();
      this.stepper.next();
      this.scrollToTop();
    } else {
      this.getEstimation();
      this.stepper.next();
      this.scrollToTop();
      this.bookNow();
    }
  }


  scrollToTop() {
    const container = document.querySelector('#booking');
    container.scrollTop = 0;
  }

  getEstimation() {

    this.routeDetails = {
      'origin': this.firstFormGroup.get('origin').value,
      'originCityName': this.firstFormGroup.get('origin').value,
    }

    let distance = 40;

    this.cars.forEach(car => {

      car['tripEstimation'] = distance * car.airport;

      car['driverBatta'] = 0;

      car['driverBattaPerDay'] = 0;

      car['ratePerKM'] = car.airport;

      car['distance'] = 40;

      car['baseFare'] = car.airport * distance;


      // car['tripType'] = this.rideDetails?.tripType;
      // car['pickupDate'] = this.rideDetails?.pickupDate;
      // car['pickupTime'] = this.rideDetails?.pickupTime;
    });

    this.show = true;
  }

  packageChange(pkg) {
    this.getEstimation();
  }



  bookNow() {
    this.bookingDetails = {
      ...this.rideDetails,
      ...this.firstFormGroup.value,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    console.log(this.bookingDetails);

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.origin,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('DD/MM/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('DD/MM/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Website Enquiry From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      logoURL: this.settings.logoURL

    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let rentalSMSBody = `
    Welcome to ${this.settings.siteName}  \r\n
    Your Booking Enquiry \r\n
    ID : ${bookingDetails.bookingId} \r\n
    Local Package Details \r\n
    Name : ${bookingDetails.name} \r\n
    Mobile : ${bookingDetails.phoneNo}  \r\n
    Pickup : ${bookingDetails.originCityName}  \r\n
    Date : ${bookingDetails.bookedDate} \r\n
    Time : ${bookingDetails.bookedTime} \r\n
    Cab : ${bookingDetails.carType} \r\n
    Hours : ${bookingDetails.duration} \r\n
    Amount Rs : ₹ ${bookingDetails.estimate}  \r\n
    Extra Km Rs : ₹ ${bookingDetails.ratePerKM} \r\n
    Toll Gate, Parking, State Permit, Hills Charges Extra \r\n
    HAPPY JOURNEY \r\n
    OFFICE : ${this.settings.phoneNo} \r\n
    Website : ${this.settings.websiteAddress}
    `
    let telegramBody = {
      message: rentalSMSBody,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    this.show = true;
  }

  confirm() {
    this.bookingDetails = {
      ...this.rideDetails,
      ...this.firstFormGroup.value,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    console.log(this.bookingDetails);

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.origin,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('DD/MM/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('DD/MM/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Website Confirmation From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      logoURL: this.settings.logoURL

    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let rentalSMSBody = `
    Welcome to ${this.settings.siteName}  \r\n
    Your Booking Confirmation \r\n
    ID : ${bookingDetails.bookingId} \r\n
    Local Package Details \r\n
    Name : ${bookingDetails.name} \r\n
    Mobile : ${bookingDetails.phoneNo}  \r\n
    Pickup : ${bookingDetails.originCityName}  \r\n
    Date : ${bookingDetails.bookedDate} \r\n
    Time : ${bookingDetails.bookedTime} \r\n
    Cab : ${bookingDetails.carType} \r\n
    Hours : ${bookingDetails.duration} \r\n
    Amount Rs : ₹ ${bookingDetails.estimate}  \r\n
    Extra Km Rs : ₹ ${bookingDetails.ratePerKM} \r\n
    Toll Gate, Parking, State Permit, Hills Charges Extra \r\n
    HAPPY JOURNEY \r\n
    OFFICE : ${this.settings.phoneNo} \r\n
    Website : ${this.settings.websiteAddress}
    `

    let whatsappMessage = window.encodeURIComponent(rentalSMSBody);

    let telegramBody = {
      message: rentalSMSBody,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");


    this.stepper.next();
    this.scrollToTop();
    this.show = true;
  }

  home() {
    window.open(`https://${this.settings.websiteAddress}`, '_self');
  }


  sendOtp(resend?) {
    if (resend) {
      this.showTimer = true;
      this.timer(1);
    }
    this.sentOtpCode = Math.floor(1000 + Math.random() * 9000);
    let otpBody = {
      "variables_values": `${this.sentOtpCode}`,
      "route": "otp",
      "numbers": this.rideDetails.phone,
      'smsKey': this.settings.smsKey
    }

    if (this.settings.OTP) {
      this.http.post(this.settings.OTPApi, otpBody).subscribe(res => {
        this.successfullySentOtpMsg = true;
        console.log(res);
      }, (e => {
        this.showOtpError;
      }));
    }
  }

  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    this.invalideOtp = false;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  verifyOtp() {
    this.successfullySentOtpMsg = false;
    const { input1, input2, input3, input4 } = this.form.value;
    this.enteredOtpCode = `${input1}${input2}${input3}${input4}`;
    if (this.sentOtpCode === +this.enteredOtpCode) {
      this.bookNow();
    } else {
      this.invalideOtp = true;
    }
  }

  timer(minute) {
    let seconds: number = minute * 30;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 30;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.showTimer = false;
      }
    }, 1000);
  }

}
