<div *ngIf="showLoader" id="preloader">
    <div id="status"></div>
</div>


<header class="main_header_area">
    <div class="header_menu" id="header_menu">
        <nav class="navbar navbar-default">
            <div class="container">
                <div class="navbar-flex d-flex align-items-center justify-content-between w-100">

                    <div class="navbar-header">
                        <a class="navbar-brand" href="index.html">
                            <img src="assets/logo.png" alt="image">
                        </a>
                    </div>

                    <div class="navbar-collapse1 d-flex align-items-center" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav" id="responsive-menu">

                            <li><a href="index.html">Home</a></li>

                            <li><a href="/about">About Us</a></li>
                            <li><a href="/tariff">Tariff</a></li>
                            <li><a href="/city">Outstation Routes</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div id="slicknav-mobile"></div>
                </div>
            </div>
        </nav>
    </div>

</header>

<div class="tet"></div>